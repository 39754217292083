import { useEffect, useRef } from 'react';
import { Edges } from '@react-three/drei';
import { Color } from 'three';
import { useFrame } from '@react-three/fiber';
import useSpline from '@splinetool/r3f-spline';
import hologramMat from '../../../shaders/hologram/hologram.mat';
// import wireframeMat from '../../../shaders/wireframe/wireframe.mat';
import useViewport from '../../../hooks/useViewport';
// import { useControls } from 'leva';

const colorHex = '#11ffef';
const modelURL = 'https://prod.spline.design/vuypwAqTXzb4U3R5/scene.splinecode';

type Vector3 = [x: number, y: number, z: number];

const SolidantLogo = (props: JSX.IntrinsicElements['group']) => {
    // We are using on mouse move with ref to avoid component rerenders
    const mouse = useRef({ x: 0, y: 0 });
    const { nodes } = useSpline(modelURL);
    const { breakpoint } = useViewport();
    const modelMesh: any = useRef();
    const holo = hologramMat(new Color(colorHex));

    const SCALE: Vector3 = Array(3).fill(0.006) as Vector3;
    SCALE[2] = 0.003;

    let POSITIONS: Vector3 = [0, 0, 0];

    // const red = new MeshLambertMaterial({ color: 'red' });

    if (breakpoint === 'laptop') {
        POSITIONS = [0.55, -0.35, 0.12];
    } else if (breakpoint === 'tablet') {
        POSITIONS = [0, -0.35, 0.12];
    } else if (breakpoint === 'phablet') {
        POSITIONS = [0, -0.35, 0.12];
    } else if (breakpoint === 'mobile') {
        POSITIONS = [0, -0.35, 0.12];
    } else {
        POSITIONS = [0.65, -0.35, 0.12];
    }

    const onMouseMove = (e: MouseEvent) => {
        mouse.current.x = e.clientX / window.innerWidth - 0.5;
        mouse.current.y = e.clientY / window.innerHeight - 0.5;
    };

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    useFrame(({ clock, frameloop }) => {
        holo.uniforms.uTime.value += 22;
        const time = clock.getElapsedTime();

        if (modelMesh.current) {
            // modelMesh.current.rotation.y += 0.003;
            modelMesh.current.rotation.y =
                Math.sin(mouse.current.x - 0.3) * 0.5;

            // modelMesh.current.rotation.x =
            //     Math.sin(mouse.current.y - 0.3) * 0.5;
            // modelMesh.current.rotation.x = mouse.current.y * 0.5 - 0.5;

            modelMesh.current.position.y = Math.sin(time) * 0.01 - 0.35;
            // holo.uniforms.uY.value += 0.1;
            // modelMesh.current.uY += 0.003;
            // rmodelMesh.current.position.y = Math.sin(elapsedTime);
            // modelMesh.current.position.z = Math.cos(elapsedTime);
        }
    });

    return (
        // <PivotControls anchor={[0, 0, 0]}>
        <group
            dispose={null}
            ref={modelMesh}
            position={[...POSITIONS]}
            // position={[positions.x, positions.y, positions.z]}
            rotation={[0.1, -0.2, 0]}
        >
            <mesh
                name='SolidantLogoBlender'
                geometry={nodes.SolidantLogoBlender.geometry}
                material={holo}
                rotation={[0, 0, 0]}
                scale={[...SCALE]}
            />

            <mesh
                geometry={nodes.SolidantLogoBlender.geometry}
                scale={[...SCALE]}
                rotation-z={0}
                rotation={[0, 0, 0]}
                position={[0, 0, 0]}
            >
                <meshBasicMaterial
                    attach='material'
                    color={colorHex}
                    transparent={true}
                    opacity={0}
                />
                <Edges
                    threshold={12}
                    renderOrder={1000}
                    scale={1.001}
                    color='blue'
                >
                    <shaderMaterial attach='material' {...holo} />
                </Edges>
            </mesh>

            {/* <mesh
                name='SolidantLogoBlender'
                geometry={nodes.SolidantLogoBlender.geometry}
                material={wireframe}
                rotation={[0, 0, 0]}
                scale={[...SCALE]}
            /> */}
        </group>
        // </PivotControls>
    );
};

export default SolidantLogo;
