import { forwardRef } from 'react';
import SmartContractDev from '../../assets/images/smart-contract.png';
import SmartContractAudit from '../../assets/images/smart-contract-audits.png';
import EndToEndInfrastructure from '../../assets/images/end-to-end-infrastructure.png';

type IServicesProps = {};

const cardList = [
    {
        element: (
            <img src={SmartContractDev} alt='Smart contract development' />
        ),
        title: 'Smart Contract Development',
        text: 'We write efficient, scaleable code, utilising the best practices your company needs for success.',
    },
    {
        element: <img src={SmartContractAudit} alt='Smart contract audits' />,
        title: `Smart Contract Audits`,
        text: 'The skilled second-set of eyes to confirm your code works exactly as you think, & to identify bugs before they reach users.',
    },
    {
        element: (
            <img
                src={EndToEndInfrastructure}
                alt='Smart contract development'
            />
        ),
        title: 'End-to-End Infrastructure Development',
        text: 'Reduce your downtime & ensure a stable service for your users through our custom infrastructure solutions.',
    },
];

const Services = forwardRef<HTMLDivElement, IServicesProps>((props, ref) => {
    return (
        <section ref={ref} id='services'>
            <div className='container'>
                <div className='title'>
                    <h2 className='highlight'>Services</h2>
                    <p className='lead'>
                        Solutions to support your goals, <br />
                        wherever you are in your development cycle.
                    </p>
                </div>
                <div className='service-list'>
                    {cardList.map(({ element, title, text }, index) => (
                        <div className='card' key={index}>
                            <div className='card-icon'>{element}</div>
                            <div className='card-text'>
                                <h3>{title}</h3>
                                <p>{text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
});

export default Services;
