const Infinitecube = () => (
    <svg
        id='infinitecube'
        height='48'
        viewBox='0 0 422 128'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g>
            <path
                opacity='1.00'
                d=' M 50.79 3.68 C 51.77 2.62 53.10 3.54 54.18 3.85 C 74.43 11.40 94.73 18.86 115.00 26.37 C 99.76 37.04 84.46 47.64 69.23 58.31 C 68.22 59.39 66.88 58.42 65.78 58.12 C 45.54 50.58 25.28 43.12 5.02 35.63 C 20.25 24.94 35.56 14.36 50.79 3.68 Z'
            />
            <path
                opacity='1.00'
                d=' M 71.02 64.27 C 87.02 53.86 102.99 43.42 118.99 33.01 C 119.00 53.92 119.02 74.83 118.98 95.74 C 102.98 106.13 87.01 116.58 71.01 126.98 C 71.00 106.08 70.98 85.17 71.02 64.27 Z'
            />
            <path
                opacity='1.00'
                d=' M 1.01 41.02 C 22.02 48.93 43.05 56.78 64.02 64.79 C 63.97 85.86 64.02 106.93 63.99 128.00 L 63.87 128.00 C 42.94 120.01 21.93 112.21 0.99 104.25 C 1.01 83.18 0.99 62.10 1.01 41.02 Z'
            />
            <path
                opacity='1.00'
                d=' M 194.95 45.98 C 197.83 42.99 202.45 42.86 206.28 43.60 C 206.27 44.58 206.24 46.53 206.23 47.51 C 203.72 47.31 200.71 46.64 198.71 48.64 C 196.72 51.35 197.71 54.89 197.52 58.00 C 200.43 57.99 203.34 58.00 206.25 58.01 C 206.24 59.33 206.24 60.66 206.25 61.99 C 203.33 62.00 200.42 62.00 197.51 62.00 C 197.41 71.69 197.66 81.38 197.39 91.07 C 195.84 91.02 194.30 90.99 192.75 90.98 C 192.74 81.32 192.75 71.67 192.75 62.02 C 191.08 62.01 189.41 61.99 187.75 61.99 C 187.75 60.66 187.75 59.34 187.75 58.01 C 189.42 58.00 191.09 57.99 192.76 57.98 C 192.82 53.96 192.07 49.27 194.95 45.98 Z'
            />
            <path
                opacity='1.00'
                d=' M 267.37 44.46 C 273.17 43.17 279.20 43.00 285.12 43.44 C 288.41 43.90 292.49 44.61 294.10 47.94 C 295.81 51.08 295.80 54.76 295.77 58.24 C 294.03 58.24 292.29 58.25 290.55 58.26 C 290.33 55.11 291.05 50.78 287.91 48.86 C 283.11 47.09 277.83 47.78 272.83 48.04 C 269.64 48.28 265.27 48.87 264.73 52.79 C 263.27 60.75 264.16 68.95 264.50 76.99 C 264.96 80.09 264.56 84.54 268.27 85.73 C 274.32 87.75 281.00 87.19 287.20 86.21 C 292.02 85.07 291.15 79.01 291.26 75.24 C 293.04 75.24 294.81 75.27 296.59 75.34 C 296.26 79.83 296.77 85.02 293.69 88.72 C 290.66 90.99 286.61 90.94 283.01 91.34 C 276.78 91.59 270.16 92.01 264.32 89.42 C 260.81 87.78 259.87 83.64 259.29 80.17 C 258.14 71.52 258.32 62.74 258.90 54.05 C 258.87 49.30 262.92 45.40 267.37 44.46 Z'
            />
            <path
                opacity='1.00'
                d=' M 141.75 43.75 C 143.33 43.76 144.91 43.75 146.50 43.75 C 146.50 45.58 146.50 47.42 146.50 49.25 C 144.91 49.25 143.33 49.24 141.75 49.25 C 141.75 47.41 141.75 45.58 141.75 43.75 Z'
            />
            <path
                opacity='1.00'
                d=' M 210.26 43.76 C 211.83 43.76 213.41 43.75 215.00 43.75 C 215.00 45.59 215.00 47.43 215.00 49.26 C 213.40 49.25 211.81 49.22 210.23 49.19 C 210.24 47.38 210.25 45.57 210.26 43.76 Z'
            />
            <path
                opacity='1.00'
                d=' M 302.25 43.76 C 303.99 43.75 305.72 43.74 307.46 43.74 C 307.59 55.47 307.40 67.21 307.55 78.94 C 307.72 81.76 308.31 85.71 311.75 86.20 C 317.81 87.43 324.19 87.33 330.24 86.13 C 333.85 85.36 334.08 80.99 334.24 78.03 C 334.27 66.60 334.24 55.18 334.25 43.76 C 335.99 43.75 337.73 43.75 339.48 43.74 C 339.51 55.46 339.53 67.19 339.47 78.91 C 339.20 82.41 338.68 86.47 335.75 88.82 C 331.98 91.19 327.33 91.25 323.02 91.50 C 317.78 91.53 312.29 91.60 307.39 89.51 C 303.38 87.77 302.27 82.97 302.25 79.00 C 302.25 67.26 302.25 55.51 302.25 43.76 Z'
            />
            <path
                opacity='1.00'
                d=' M 348.48 90.97 C 348.54 75.23 348.45 59.48 348.53 43.74 C 356.36 43.79 364.20 43.68 372.03 43.79 C 375.76 44.08 380.86 44.45 382.20 48.71 C 384.12 54.68 384.29 63.61 377.29 66.28 C 381.88 67.02 384.67 71.55 384.73 75.96 C 384.84 80.42 384.65 85.85 380.77 88.81 C 377.97 90.82 374.32 90.96 371.00 91.01 C 363.49 90.97 355.99 91.05 348.48 90.97 M 353.76 48.25 C 353.74 53.67 353.74 59.08 353.76 64.49 C 360.78 64.19 367.95 65.26 374.85 63.69 C 378.07 62.69 378.18 58.81 378.28 56.03 C 378.38 53.16 377.87 49.00 374.26 48.68 C 367.48 47.76 360.58 48.47 353.76 48.25 M 353.75 69.01 C 353.74 74.83 353.74 80.66 353.75 86.49 C 359.80 86.46 365.84 86.59 371.89 86.44 C 374.53 86.25 378.23 85.99 379.12 82.94 C 380.13 79.39 379.98 75.50 378.96 71.98 C 378.12 69.59 375.18 69.49 373.08 69.20 C 366.65 68.76 360.19 69.10 353.75 69.01 Z'
            />
            <path
                opacity='1.00'
                d=' M 390.24 43.79 C 400.42 43.73 410.59 43.71 420.77 43.80 C 420.75 45.28 420.74 46.76 420.74 48.24 C 412.35 48.25 403.95 48.28 395.55 48.22 C 395.44 53.64 395.52 59.07 395.51 64.49 C 403.51 64.52 411.50 64.47 419.51 64.51 C 419.50 66.01 419.49 67.50 419.50 69.00 C 411.50 69.01 403.50 68.99 395.51 69.00 C 395.50 74.83 395.50 80.66 395.51 86.49 C 403.92 86.50 412.32 86.50 420.73 86.50 C 420.74 87.99 420.74 89.49 420.76 90.98 C 410.59 91.01 400.42 91.01 390.25 90.99 C 390.25 75.25 390.26 59.52 390.24 43.79 Z'
            />
            <path
                opacity='1.00'
                d=' M 141.75 58.00 C 143.34 58.01 144.92 58.02 146.51 58.02 C 146.50 69.00 146.49 79.99 146.52 90.97 C 144.93 90.98 143.34 90.99 141.75 91.01 C 141.75 80.00 141.75 69.00 141.75 58.00 Z'
            />
            <path
                opacity='1.00'
                d=' M 155.00 58.00 C 156.51 58.00 158.01 58.00 159.52 58.00 C 159.49 59.47 159.46 60.94 159.42 62.41 C 161.97 58.09 167.36 57.22 171.97 57.49 C 175.19 57.70 179.05 58.05 181.03 61.00 C 182.60 63.70 182.77 66.95 182.76 70.00 C 182.74 77.00 182.75 83.99 182.76 90.99 C 181.17 90.99 179.59 91.00 178.00 91.00 C 177.97 83.31 178.04 75.62 177.98 67.94 C 177.96 65.74 177.40 62.85 174.96 62.15 C 171.40 61.11 167.38 61.16 163.98 62.73 C 160.93 64.22 159.66 67.77 159.75 70.98 C 159.73 77.65 159.76 84.32 159.76 90.99 C 158.17 90.99 156.59 91.00 155.00 91.00 C 155.00 80.00 155.00 69.00 155.00 58.00 Z'
            />
            <path
                opacity='1.00'
                d=' M 210.25 58.01 C 211.83 58.01 213.41 58.00 215.00 58.00 C 215.00 69.00 215.00 80.00 215.00 91.00 C 213.41 91.00 211.83 90.99 210.24 90.99 C 210.26 79.99 210.25 69.00 210.25 58.01 Z'
            />
            <path
                opacity='1.00'
                d=' M 223.48 58.03 C 224.99 58.02 226.49 58.00 228.00 57.99 C 228.00 59.48 227.99 60.98 227.97 62.47 C 229.73 59.18 233.49 57.74 237.05 57.56 C 240.63 57.37 244.50 57.40 247.70 59.21 C 250.60 61.07 251.03 64.86 251.23 67.99 C 251.28 75.66 251.24 83.34 251.25 91.01 C 249.65 90.99 248.06 90.97 246.48 90.96 C 246.51 83.32 246.51 75.67 246.49 68.03 C 246.44 65.90 246.03 63.15 243.77 62.28 C 239.99 61.05 235.57 61.07 232.02 63.01 C 229.22 64.55 228.22 67.95 228.26 70.95 C 228.22 77.64 228.27 84.32 228.25 91.01 C 226.65 90.99 225.07 90.98 223.49 90.98 C 223.50 79.99 223.51 69.01 223.48 58.03 Z'
            />
        </g>
    </svg>
);

export default Infinitecube;
