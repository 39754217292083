import {
    Color,
    ShaderMaterial,
    Vector2,
    UniformsUtils,
    UniformsLib,
} from 'three';
import fragment from './fragment.glsl.js';
import vertex from './vertex.glsl.js';

const colorHex = '#11ffef';

const holoStripes = () => {
    const uniforms = UniformsUtils.merge([UniformsLib['fog']]);

    // When merging uniforms have to be defined in the shader as below
    uniforms.uColor = { value: new Color(colorHex) };
    uniforms.uConcavity = { value: 0.0 };
    uniforms.uTime = { value: 0 };
    uniforms.uGridOffset = { value: new Vector2() };
    uniforms.uRound = { value: false };

    return new ShaderMaterial({
        vertexShader: vertex,
        fragmentShader: fragment,
        wireframe: false,
        transparent: true,
        depthTest: true,
        depthWrite: false,
        fog: true,
        lights: false,
        uniforms: uniforms,
    });
};

export default holoStripes;
