import { forwardRef, Suspense, lazy, useRef, useState } from 'react';
import Button from '../../components/atoms/Button';
import Spool from '../../assets/icons/Logo/Spool';
import Atka from '../../assets/icons/Logo/Atka';
import Faculty from '../../assets/icons/Logo/Faculty';
import Anboto from '../../assets/icons/Logo/Anboto';
import Gamestar from '../../assets/icons/Logo/Gamestar';
import Unlimited from '../../assets/icons/Logo/Unlimited';
import Scene from '../../components/molecules/Scene/Scene';
import useInView from '../../hooks/useInView';
// import { degToRad } from 'three/src/math/MathUtils';
import { PerformanceMonitor } from '@react-three/drei';
import { calcMinDpr, degToRad, sendEmail } from '../../utils/fn';
import { dpr, IHomeProps } from '../../types';
import Infinitecube from '../../assets/icons/Logo/Infinitecube';
import Holoride from '../../assets/icons/Logo/Holoride';

// lazy load the Canvas from react-three-fiber
const Canvas = lazy(() =>
    import('@react-three/fiber').then((module) => ({
        default: module.Canvas,
    }))
);

const Home = forwardRef<HTMLDivElement, IHomeProps>((props, ref) => {
    const [dpr, setDpr] = useState<dpr>([1, 1.5]);

    const canvasRef = useRef(null);
    const isInView = useInView(canvasRef, { rootMargin: '100px' });

    const created = (state: any) => {
        const { camera } = state;

        camera.position.set(0, 0, 1);
        camera.zoom = 1.0;
        camera.fov = 70;
        camera.far = 100000;
        camera.rotation.set(-degToRad(2), degToRad(0), degToRad(0));
        // camera.lookAt(0.64, -0.4, 0);
        camera.updateProjectionMatrix();
    };

    return (
        <section ref={ref} id='home'>
            <div className='container'>
                <div className='main'>
                    <div className='title'>
                        <h2>
                            We invent, design &amp;
                            <br />
                            develop your cutting-edge
                            <br />
                            <span className='highlight'>blockchain</span>{' '}
                            solutions.
                        </h2>
                        <p className='lead'>
                            Bespoke decentralised solutions, built to your
                            requirements, to fulfil your users’ needs.
                        </p>
                        <Button variant='holo' size='large' onClick={sendEmail}>
                            Get in touch
                        </Button>
                    </div>

                    <div className='logo-showcase'>
                        <div className='canvas' ref={canvasRef}>
                            <span className='left-lights'></span>
                            <span className='right-lights'></span>
                            <Suspense fallback={null}>
                                <Canvas
                                    dpr={dpr}
                                    onCreated={created}
                                    // orthographic
                                    frameloop={isInView ? 'always' : 'demand'}
                                >
                                    <PerformanceMonitor
                                        onChange={({ factor }) => {
                                            setDpr(calcMinDpr(factor));
                                            // console.log('dpr updated');
                                        }}
                                        onDecline={() => setDpr([0.5, 1])}
                                        flipflops={3}
                                    >
                                        <fog
                                            attach='fog'
                                            args={['#000532', 1, 4]}
                                        />
                                        <ambientLight />
                                        <Scene />
                                    </PerformanceMonitor>
                                </Canvas>
                            </Suspense>
                        </div>
                    </div>
                </div>
                <div className='trusted'>
                    <div className='spot-light left'></div>
                    <h5>TRUSTED BY</h5>
                    <br />
                    <div className='trusted-icons'>
                        <Spool />
                        <Gamestar />
                        <Unlimited />
                        <Faculty />
                        <Anboto />
                        <Atka />
                        <Infinitecube />
                        <Holoride />
                    </div>
                </div>
            </div>
        </section>
    );
});

export default Home;
