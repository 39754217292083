import { FC, useState, useEffect, useMemo, RefObject } from 'react';
import LogoIcon from '../../assets/icons/Logo/SolidantLogo';
import { RefTypes } from '../../App';
import Button from '../../components/atoms/Button';
import { sendEmail } from '../../utils/fn';

type INavbarProps = { refList: RefTypes };

const minScrollYDistance = 80;

const Navbar: FC<INavbarProps> = ({ refList }) => {
    const { homeRef, aboutUsRef, servicesRef, partnersRef } = refList;

    const links = useMemo(
        () => [
            { sectionTitle: 'Home', sectionRef: homeRef },
            { sectionTitle: 'About', sectionRef: aboutUsRef },
            { sectionTitle: 'Services', sectionRef: servicesRef },
            { sectionTitle: 'Partners', sectionRef: partnersRef },
        ],
        [homeRef, aboutUsRef, servicesRef, partnersRef]
    );

    const [currentActive, setCurrentActive] = useState<string>('Home');
    const [backgroundColor, setBackgroundColor] = useState<boolean>(false);

    // Unmount Effect
    useEffect(() => {
        const scrollListener = () =>
            setBackgroundColor(window.scrollY >= minScrollYDistance);

        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    // Section Highlighting
    useEffect(() => {
        const options = {
            threshold: 0.5,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setCurrentActive(entry.target.id);
                }
            });
        }, options);

        links.forEach(({ sectionRef }) => {
            if (sectionRef.current) {
                observer.observe(sectionRef.current);
            }
        });

        return () => observer.disconnect();
    }, [aboutUsRef, homeRef, servicesRef, partnersRef, links]);

    // Scroll
    const scrollTo = (ele: RefObject<HTMLDivElement>) => {
        if (!ele.current) return;
        ele.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <header className={`${backgroundColor ? 'scroll-active' : ''}`}>
            <div className='container'>
                <div className='logo'>
                    <LogoIcon />
                </div>
                <nav className='nav-links'>
                    {links.map(({ sectionTitle, sectionRef }, index) => (
                        <span
                            key={index}
                            className={`${
                                currentActive === sectionTitle.toLowerCase()
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => scrollTo(sectionRef)}
                        >
                            {sectionTitle}
                        </span>
                    ))}
                    <Button variant='secondary' onClick={sendEmail}>
                        Contact Us
                    </Button>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
