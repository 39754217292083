import { FC } from 'react';
import LogoIcon from '../../assets/icons/Logo/SolidantLogo';

type IFooterProps = {};

const Footer: FC<IFooterProps> = (props) => {
    // current year
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <div className='container'>
                <LogoIcon />
                <span>All rights reserved. © {currentYear} Solidant.</span>
            </div>
        </footer>
    );
};

export default Footer;
