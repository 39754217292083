import { FC, useRef, MutableRefObject } from 'react';
import './css/App.scss';
import Navbar from './layout/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Partners from './pages/Partners';
import Footer from './layout/Footer';

export type RefTypes = {
    homeRef: MutableRefObject<HTMLInputElement | null>;
    aboutUsRef: MutableRefObject<HTMLInputElement | null>;
    servicesRef: MutableRefObject<HTMLInputElement | null>;
    partnersRef: MutableRefObject<HTMLInputElement | null>;
};

const App: FC = () => {
    const homeRef = useRef<HTMLInputElement | null>(null);
    const aboutUsRef = useRef<HTMLInputElement | null>(null);
    const servicesRef = useRef<HTMLInputElement | null>(null);
    const partnersRef = useRef<HTMLInputElement | null>(null);
    const refList = { homeRef, aboutUsRef, servicesRef, partnersRef };
    return (
        <>
            <Navbar refList={refList} />
            <Home ref={homeRef} />
            <AboutUs ref={aboutUsRef} />
            <Services ref={servicesRef} />
            <Partners ref={partnersRef} />
            <Footer />
        </>
    );
};

export default App;
