import React, { forwardRef } from 'react';
import Button from '../../components/atoms/Button';
import Spool from '../../assets/icons/Logo/Spool';
import Atka from '../../assets/icons/Logo/Atka';
import Faculty from '../../assets/icons/Logo/Faculty';
import Anboto from '../../assets/icons/Logo/Anboto';
import Gamestar from '../../assets/icons/Logo/Gamestar';
import Unlimited from '../../assets/icons/Logo/Unlimited';
import { sendEmail } from '../../utils/fn';
import Infinitecube from '../../assets/icons/Logo/Infinitecube';
import Holoride from '../../assets/icons/Logo/Holoride';

type IPartnersProps = {};

const Partners = forwardRef<HTMLDivElement, IPartnersProps>((props, ref) => {
    const partners = [
        <Spool />,
        <Atka />,
        <Faculty />,
        <Anboto />,
        <Gamestar />,
        <Unlimited />,
        <Infinitecube />,
        <Holoride />,
    ];

    return (
        <section ref={ref} id='partners'>
            <div className='container'>
                <div className='title'>
                    <h2 className='highlight'>Our Partners</h2>
                </div>
                <div className='flex-row align-v-center align-h-center'>
                    {partners.map((partner, index) => (
                        <div
                            className='partner col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-xs-6 col-sm-6'
                            key={index}
                        >
                            {partner}
                        </div>
                    ))}
                </div>
                <div className='project flex-row align-space-between'>
                    <div className='col-xxl-6 col-xl-6 col-sm-12 col-xs-12'>
                        <h2 className='highlight'>
                            Build your next web3 product with us.
                        </h2>
                    </div>
                    <div className='col-xxl-6 col-xl-6'>
                        <p className='lead'>
                            Get in touch today to receive a bespoke quote for
                            exactly the solution your project needs.
                        </p>
                        <Button variant='holo' size='large' onClick={sendEmail}>
                            Get in touch
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
});

export default Partners;
