import { Color, ShaderMaterial } from 'three';
import fragment from './fragment.glsl';
import vertex from './vertex.glsl';

const hologramMat = (color) => {
    return new ShaderMaterial({
        wireframe: false,
        transparent: true,
        depthTest: true,
        depthWrite: false,
        lights: false,
        vertexColors: true,
        //side: 2,
        uniforms: {
            uTime: {
                value: 0.0,
            },
            uColor: {
                value: new Color(color),
            },
        },
        defines: {},
        vertexShader: vertex,
        fragmentShader: fragment,
    });
};

export default hologramMat;
