export const fragment = `#define GLSLIFY 1

#include <fog_pars_fragment>

uniform float uTime;
uniform vec3 uColor;
uniform vec2 uGridOffset;

varying vec2 vUv;
#define uGrid1Frequency 10.0
#define uGrid1Thickness 0.98
#define uGrid1Strength .2

#define uGrid2Frequency 50.0
#define uGrid2Thickness 0.94
#define uGrid2Strength 0.1

#define uBorderThickness 0.005
#define uBorderStrength 2.0

#define uHalosTimeFrequency 0.0002
#define uHalosFrequency 10.0
#define uHalosStrength 0.33

void main(){
        // Grid
        vec2 gridUv = vUv + uGridOffset;
        

        // Big grid
        float grid1 = max(
                step(uGrid1Thickness, mod(0.5 + gridUv.x * uGrid1Frequency, 1.0)),
                step(uGrid1Thickness, mod(0.5 + gridUv.y * uGrid1Frequency, 1.0)));
        grid1 *= uGrid1Strength;
            
        // Small grid
        float grid2 = max(step(uGrid2Thickness, mod(0.5 + gridUv.x * uGrid2Frequency, 1.0)), step(uGrid2Thickness, mod(0.5 + gridUv.y * uGrid2Frequency, 1.0)));
        grid2 *= uGrid2Strength;

         grid1 = 0.0;
        // grid2 = 0.0;
            
        // Border
        float border = 1.0 - step(length(vUv - 0.5), 0.5 - uBorderThickness);
         border *= uBorderStrength;
        
        // disable border
        border = 0.0;

        // Alpha
        float alpha = step(length(vUv - 0.5), 0.5);
        alpha = 1.0;
        
        // Halos  
        float halos = 0.0;
        // halos = mod(length(vUv - 0.5) * uHalosFrequency - uTime * uHalosTimeFrequency, 1.0);
        // halos *= uHalosStrength;
                         
        // Strength
        float strength = grid1 + grid2 + border + halos;
        strength *= alpha;
                                  
        // Color  
        vec3 color = uColor * max(strength, 1.0);
        gl_FragColor = vec4(uColor, strength);
        #include <fog_fragment>     
}`;

export default fragment;
