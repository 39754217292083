import { forwardRef } from 'react';
import FeaturesMainPng from '../../assets/images/feature-main.png';
import TechHolo from '../../assets/images/tech-icon-holo.png';
import SecurityHolo from '../../assets/images/security-icon-holo.png';
import ResearchHolo from '../../assets/images/research-icon-holo.png';

type IAboutUsProps = {};

const AboutUs = forwardRef<HTMLDivElement, IAboutUsProps>((props, ref) => {
    return (
        <section ref={ref} id='about'>
            <div className='container'>
                <div className='title'>
                    <h2 className='highlight'>About Us</h2>
                    <p className='lead'>
                        We are a team of experienced developers providing
                        customised blockchain solutions.
                    </p>
                </div>
                <div className='flex-row'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                        {/* <FeaturesMain /> */}
                        <img
                            className='bm-p-lighter'
                            src={FeaturesMainPng}
                            alt='Solidant blockchain development services '
                        />
                    </div>

                    <div className='features-list col-xxl-6 col-xl-6 col-lg-12 col-md-12'>
                        <div className='feature'>
                            <img src={TechHolo} alt='Technology Holo' />
                            <div className='feature-text'>
                                <h3>Technology</h3>
                                <p>
                                    Our mission is to provide cutting-edge
                                    blockchain solutions tailored to your
                                    specific needs, developed and supported by a
                                    team of professionals with years of
                                    experience.
                                </p>
                            </div>
                        </div>
                        <div className='feature'>
                            <img src={SecurityHolo} alt='Security' />
                            <div className='feature-text'>
                                <h3>Security</h3>
                                <p>
                                    Security and privacy are valued above all
                                    else at Solidant. We use widely adopted
                                    standards with rigorous testing and review
                                    to achieve best levels of security.
                                </p>
                            </div>
                        </div>
                        <div className='feature'>
                            <img src={ResearchHolo} alt='Research' />
                            <div className='feature-text'>
                                <h3>Research</h3>
                                <p>
                                    The world of crypto is in a constant flux, a
                                    place of constant expansion and
                                    transformation. We strive to stay at the
                                    very front by investing in research,
                                    innovation and development of new
                                    leading-edge solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});

export default AboutUs;
