export const vertex = `
#define GLSLIFY 1

#include <common>
#include <fog_pars_vertex>

uniform float uConcavity;

varying vec2 vUv;

void main()
{

        #include <begin_vertex>
        #include <project_vertex>
        #include <fog_vertex>

        vec3 newPosition = position;
        
        // Elevation
        newPosition.z -= cos(length(uv - 0.5) * PI2) * uConcavity;   
        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
           
        // Varyings
        vUv = uv;
}`;

export default vertex;
