import { FC, ReactNode } from 'react';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    variant: 'primary' | 'secondary' | 'holo';
    size?: 'small' | 'medium' | 'large';
    children: ReactNode;
}

const Button: FC<ButtonProps> = ({ variant, size, children, ...rest }) => {
    return (
        <button {...rest} className={['button', size, variant].join(' ')}>
            {children}
        </button>
    );
};

export default Button;
