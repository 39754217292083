// import LogoStripes from './LogoStripes';

const Atka = () => (
    <svg
        id='atka-icon'
        viewBox='0 0 128 32'
        fill='none'
        height='60'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g>
            <path
                fill='#fff'
                d='M39.319 3.334L37.37 0l-7.392 12.654a3.23 3.23 0 01-2.78 1.597h-15.09c-1.15 0-2.206-.61-2.78-1.597L1.935 0 0 3.31l6.453 11.058a3.224 3.224 0 010 3.252L0 28.678 1.936 32l7.38-12.654a3.23 3.23 0 012.781-1.597h15.09c1.15 0 2.205.61 2.78 1.597L37.347 32l1.96-3.357-6.442-11.035a3.28 3.28 0 01.024-3.251l6.43-11.023zm87.132 3.556c-.727 0-1.361.517-1.513 1.245-.059.317-.129.634-.2.974-1.361-1.244-2.851-2.1-4.599-2.453-1.772-.352-3.52-.282-5.245.258-1.702.54-3.121 1.514-4.271 2.888-2.816 3.357-2.828 8.957-.024 12.337 1.444 1.738 3.274 2.818 5.492 3.24 3.203.599 6.042-.211 8.553-2.418.153.775.282 1.444.423 2.113H128V8.44c0-.857-.692-1.55-1.549-1.55zm-8.354 15.05c-3.309-.07-5.82-2.7-5.749-6.046.035-3.498 2.816-6.045 6.418-5.87 3.109.154 5.632 2.783 5.561 6.011-.07 3.51-2.663 5.975-6.23 5.905zM70.166 6.844a1.605 1.605 0 00-1.549 1.291 5.224 5.224 0 00-.106.927c-4.435-4.061-10.782-2.923-13.728.294-3.18 3.486-3.32 9.215-.398 12.83 2.593 3.193 8.882 5.236 14.044.822.106.564.153 1.092.317 1.585.07.211.376.481.599.493.774.059 1.548.023 2.417.023V8.44c0-.88-.716-1.596-1.596-1.596zM62.07 21.94c-3.462-.012-5.96-2.536-5.949-5.999.023-3.474 2.511-5.916 6.02-5.904 3.414.011 5.925 2.523 5.925 5.94.011 3.439-2.547 5.975-5.996 5.963zm19.935-3.745c-.012-2.441 0-8.111 0-8.111h5.35V6.867h-5.35v-4.32h-1.408a2.42 2.42 0 00-2.1 1.21l-3.685 6.362 3.837.012s-.058 6.315 0 9.003c.07 2.818 1.655 4.977 4.26 5.576 1.56.352 2.921.352 2.921.352l1.948-3.357c-.012-.012-1.35 0-1.983-.012-2.276-.058-3.778-1.232-3.79-3.498zm20.885-4.402l4.05-6.914h-3.811l-3.078 5.259a3.142 3.142 0 01-2.722 1.561h-2.276V2.136c0-.939-.763-1.714-1.713-1.714-.951 0-1.714.763-1.714 1.714V25.11h3.415v-7.994h2.276c1.127 0 2.159.599 2.722 1.561l3.755 6.433h3.837l-4.752-8.147a3.212 3.212 0 01.011-3.17z'
            />
        </g>
        {/* <LogoStripes /> */}
    </svg>
);

export default Atka;
