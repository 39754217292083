import { FC } from 'react';
import SolidantLogo from '../SolidantLogo/SolidantLogo';
import HoloFloor from '../HoloFloor/HoloFloor';
// import { OrbitControls } from '@react-three/drei';
// import { EffectComposer, SMAA } from '@react-three/postprocessing';

const Scene: FC = () => {
    return (
        <>
            {/* <OrbitControls
                makeDefault
                enableZoom={true}
                enablePan={true}
                enableRotate={true}
            /> */}

            {/* <EffectComposer multisampling={0}>
                <SMAA />
            </EffectComposer> */}

            <SolidantLogo />
            <HoloFloor />
        </>
    );
};

export default Scene;
