import { dpr } from '../types';

export const degToRad = (degrees: number): number => {
    return degrees * (Math.PI / 180);
};

export const round = (num: number, decimals: number = 1): number => {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
};

export const clamp = (num: number, min: number, max: number): number => {
    return Math.min(Math.max(num, min), max);
};

export const calcMinDpr = (num: number): dpr => {
    const min = clamp(round(0.5 + 1.5 * num, 1), 0.3, 1.5);
    const max = min + 0.5;

    return [min, max];
};

export const sendEmail = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.href = 'mailto:info@solidant.io?subject=Contact us';
};
