import { MutableRefObject, RefObject, useEffect, useState } from 'react';

const useInView = (
    ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement | null>,
    options: IntersectionObserverInit = {}
): boolean => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [observer, setObserver] = useState<IntersectionObserver | null>(null);

    useEffect(() => {
        const handleIntersect = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                setIsIntersecting(entry.isIntersecting);
            });
        };

        if (observer) {
            observer.disconnect();
        }

        if (ref.current) {
            const initObserver = new IntersectionObserver(
                handleIntersect,
                options
            );

            initObserver.observe(ref.current);
            setObserver(initObserver);
        }
    }, [ref.current, options.rootMargin, options.threshold, options.root]); // eslint-disable-line

    useEffect(() => {
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, []); // eslint-disable-line

    return isIntersecting;
};

export default useInView;
