// import LogoStripes from './LogoStripes';

const Faculty = () => (
    <svg
        id='faculty-icon'
        width='216'
        height='62'
        viewBox='0 0 216 62'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_706_64414)'>
            <path
                d='M69.7394 24.0255V0.405029H86.344V2.02472H71.4908V11.7091H84.8287V13.3288H71.4908V24.0255H69.7394Z'
                fill='white'
            />
            <path
                d='M85.7772 24.0252L96.7574 0.236084H98.4415L109.422 24.0252H107.502L104.504 17.4115H90.5939L87.5963 24.0252H85.7772ZM91.3348 15.8256H103.763L97.5656 2.15946L91.3348 15.8256Z'
                fill='white'
            />
            <path
                d='M122.389 24.4303C119.021 24.4303 116.225 23.2605 114.002 20.921C111.779 18.5814 110.668 15.702 110.668 12.2826V12.2151C110.668 8.8408 111.79 5.96137 114.036 3.57681C116.281 1.19227 119.088 0 122.456 0C124.409 0 126.082 0.326187 127.475 0.978562C128.889 1.60844 130.27 2.54201 131.617 3.77927L130.371 5.06153C127.991 2.74447 125.341 1.58594 122.422 1.58594C119.593 1.58594 117.224 2.6095 115.316 4.6566C113.43 6.68123 112.486 9.17824 112.486 12.1476V12.2151C112.486 15.1845 113.441 17.7041 115.349 19.7737C117.28 21.8208 119.649 22.8443 122.456 22.8443C124.095 22.8443 125.543 22.5519 126.801 21.967C128.058 21.3596 129.316 20.4261 130.573 19.1663L131.752 20.3136C130.36 21.6633 128.934 22.6869 127.475 23.3842C126.038 24.0816 124.342 24.4303 122.389 24.4303Z'
                fill='white'
            />
            <path
                d='M144.691 24.3967C141.794 24.3967 139.459 23.5081 137.685 21.7309C135.911 19.9538 135.025 17.4455 135.025 14.2061V0.405029H136.776V14.0374C136.776 16.8493 137.472 19.0202 138.864 20.5499C140.279 22.0571 142.243 22.8107 144.759 22.8107C147.184 22.8107 149.092 22.0796 150.484 20.6174C151.899 19.1326 152.606 16.9956 152.606 14.2061V0.405029H154.358V14.0037C154.358 17.3105 153.482 19.875 151.73 21.6972C149.979 23.4968 147.633 24.3967 144.691 24.3967Z'
                fill='white'
            />
            <path
                d='M159.638 24.0255V0.405029H161.389V22.4058H175.232V24.0255H159.638Z'
                fill='white'
            />
            <path
                d='M182.319 24.0255V2.02472H174.101V0.405029H192.288V2.02472H184.07V24.0255H182.319Z'
                fill='white'
            />
            <path
                d='M203.763 24.0255V14.6785L193.659 0.405029H195.848L204.673 13.0926L213.531 0.405029H215.619L205.515 14.6448V24.0255H203.763Z'
                fill='white'
            />
            <path
                d='M80.5512 61.6855C76.9358 61.6855 74.0509 60.5268 71.8949 58.21C69.7619 55.8703 68.6956 52.9796 68.6956 49.5379V49.4702C68.6956 46.1635 69.7843 43.3065 71.9622 40.8994C74.1406 38.4699 76.9358 37.2551 80.3489 37.2551C82.19 37.2551 83.7734 37.5138 85.0979 38.0312C86.4227 38.5261 87.7137 39.3022 88.9713 40.3595L87.8263 41.6755C85.6708 39.7859 83.1446 38.8411 80.2478 38.8411C77.4186 38.8411 75.0833 39.8759 73.2422 41.9454C71.4236 43.9926 70.5142 46.4784 70.5142 49.4029V49.4702C70.5142 52.597 71.4236 55.1619 73.2422 57.1639C75.0609 59.1435 77.5198 60.1333 80.6185 60.1333C82.033 60.1333 83.4027 59.8857 84.7276 59.3911C86.0749 58.8735 87.2085 58.221 88.1293 57.4339V50.6513H80.2478V49.0318H89.8134V58.1423C87.1188 60.5044 84.0315 61.6855 80.5512 61.6855Z'
                fill='white'
            />
            <path
                d='M94.8041 61.2806V37.6602H104.706C107.58 37.6602 109.792 38.4363 111.341 39.9885C112.532 41.1807 113.127 42.6429 113.127 44.3753V44.4426C113.127 46.332 112.487 47.8618 111.207 49.0319C109.949 50.1791 108.288 50.8989 106.222 51.1913L114.002 61.2806H111.779L104.269 51.4952H96.5555V61.2806H94.8041ZM96.5555 49.9091H104.504C106.525 49.9091 108.175 49.4254 109.455 48.4581C110.735 47.4907 111.375 46.1746 111.375 44.5103V44.4426C111.375 42.8229 110.78 41.5631 109.59 40.6633C108.4 39.741 106.738 39.2798 104.605 39.2798H96.5555V49.9091Z'
                fill='white'
            />
            <path
                d='M137.129 58.1084C134.907 60.493 132.055 61.6855 128.575 61.6855C125.094 61.6855 122.254 60.493 120.054 58.1084C117.853 55.7243 116.753 52.867 116.753 49.5379V49.4702C116.753 46.1182 117.864 43.2502 120.087 40.8657C122.31 38.4586 125.162 37.2551 128.642 37.2551C132.122 37.2551 134.963 38.4474 137.163 40.8319C139.364 43.2165 140.464 46.0734 140.464 49.4029V49.4702C140.464 52.8221 139.353 55.7014 137.129 58.1084ZM121.434 56.995C123.365 59.0648 125.768 60.0994 128.642 60.0994C131.516 60.0994 133.896 59.0758 135.782 57.0289C137.691 54.9816 138.645 52.4849 138.645 49.5379V49.4702C138.645 46.5232 137.68 44.0151 135.749 41.9454C133.84 39.8759 131.449 38.8411 128.575 38.8411C125.7 38.8411 123.309 39.8646 121.401 41.9117C119.514 43.9588 118.571 46.4559 118.571 49.4029V49.4702C118.571 52.4172 119.526 54.9253 121.434 56.995Z'
                fill='white'
            />
            <path
                d='M154.361 61.6517C151.465 61.6517 149.129 60.7631 147.356 58.9862C145.582 57.2088 144.695 54.7007 144.695 51.4613V37.6602H146.446V51.2925C146.446 54.1044 147.142 56.2753 148.534 57.8051C149.949 59.312 151.914 60.0657 154.428 60.0657C156.853 60.0657 158.762 59.3349 160.154 57.8724C161.569 56.3879 162.276 54.2509 162.276 51.4613V37.6602H164.028V51.2586C164.028 54.5657 163.152 57.1301 161.401 58.9523C159.649 60.7521 157.303 61.6517 154.361 61.6517Z'
                fill='white'
            />
            <path
                d='M169.307 61.2806V37.6602H177.862C180.49 37.6602 182.6 38.3013 184.194 39.5835C185.811 40.8658 186.619 42.6317 186.619 44.8814V44.9487C186.619 47.3333 185.744 49.1893 183.993 50.5164C182.241 51.821 180.052 52.4735 177.425 52.4735H171.059V61.2806H169.307ZM171.059 50.8536H177.526C179.726 50.8536 181.5 50.3251 182.847 49.268C184.194 48.2105 184.868 46.8047 184.868 45.0498V44.9825C184.868 43.1603 184.217 41.7544 182.915 40.7646C181.612 39.7747 179.883 39.2798 177.728 39.2798H171.059V50.8536Z'
                fill='white'
            />
            <path
                d='M61.1245 0.401611H0.142198V0.680297H0V61.2461H9.26315V9.09148H61.1245V0.401611Z'
                fill='white'
            />
            <path
                d='M27.7894 52.5558V26.4762H61.1245V17.7864H27.7894H18.5262V26.4762V52.5558V61.246H27.7894H51.8667H61.1245V52.5558V43.8611V35.1712H51.8667H37.0475V43.8611H51.8667V52.5558H27.7894Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_706_64414'>
                <rect width='215.901' height='62' fill='white' />
            </clipPath>
        </defs>
        {/* <LogoStripes /> */}
    </svg>
);

export default Faculty;
