import { useEffect, useState } from 'react';

type viewPort = {
    width: Number;
    breakpoint: String;
};

const useViewport = (): viewPort => {
    const [width, setWidth] = useState<Number>(window.innerWidth);
    const [breakpoint, setBreakpoint] = useState<String>('');

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        if (width <= 480) {
            setBreakpoint('mobile');
        } else if (width > 480 && width <= 768) {
            setBreakpoint('phablet');
        } else if (width > 768 && width <= 900) {
            setBreakpoint('tablet');
        } else if (width > 900 && width <= 1120) {
            setBreakpoint('laptop');
        } else if (width > 1120 && width <= 1440) {
            setBreakpoint('desktop');
        } else {
            setBreakpoint('large-desktop');
        }
    }, [width]);

    // Return the width so we can use it in our components
    return { width, breakpoint };
};

export default useViewport;
