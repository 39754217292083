// import { GradientTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMemo } from 'react';
import holoStripes from '../../../shaders/holo-stripes/holo-stripes';

const HoloFloor = () => {
    const material = useMemo(() => {
        return holoStripes();
    }, []);

    useFrame(({ clock }) => {
        // const elapsedTime = clock.getElapsedTime();

        material.uniforms.uGridOffset.value.y += 0.0002;
    });

    return (
        <group>
            {/* <mesh
                rotation-x={-Math.PI * 0.5}
                position-y={-0.01}
                position-z={-5.4}
            >
                <planeGeometry args={[20, 12]} />
                <meshBasicMaterial transparent={true}>
                    <GradientTexture
                        stops={[0, 1]} // As many stops as you want
                        colors={['red', 'red']} // Colors need to match the number of stops
                        size={1024} // Size is optional, default = 1024
                    />
                </meshBasicMaterial>
            </mesh> */}

            <mesh rotation-x={-Math.PI * 0.3} position-y={0} position-z={-3}>
                <planeGeometry args={[20, 12]} />
                <shaderMaterial attach='material' {...material} />
            </mesh>
        </group>
    );
};

export default HoloFloor;
