const vertex = `#define GLSLIFY 1
 
#include <common>

uniform float uY;


varying float vFresnel; 
varying float vElevation; 

void main(){


	#if defined ( USE_ENVMAP ) || defined ( USE_SKINNING )

		#include <beginnormal_vertex>
		#include <morphnormal_vertex>
		#include <skinbase_vertex>
		#include <skinnormal_vertex>
		#include <defaultnormal_vertex>

	#endif


  float delta =  .3;

  vec3 p = position.xyz;

  // *Fresnel view direction when rotating the object
  // float new_x = p.x*cos(delta) - p.y*sin(delta);
  // float new_y = p.y*cos(delta) + p.x*sin(delta);

  vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec3 viewDirection = normalize(modelPosition.xyz - cameraPosition);
    vec3 worldNormal = normalize(mat3(modelMatrix[0].xyz *  0.0, modelMatrix[1].xyz, modelMatrix[2].xyz) * normal);
    
  // float fresnelOffset = 0.0;   
  // float fresnelScale = 1.0;
  // float fresnelPower = 2.0;
  // float fresnel = fresnelOffset + fresnelScale * pow(1.0 + dot(viewDirection, worldNormal), fresnelPower);
  float fresnel = max(0.0, dot(normalize(vec3(- 0.2, 0.5, - 0.5)), worldNormal));




  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);  


  // Elevation angle
  // *Fresnel view direction when rotating the object
  // vec4 modelPositionAngle = modelMatrix * vec4(p.x, new_y, p.z, 1.0);  

  vec4 modelPositionAngle = modelMatrix * vec4(p.x, p.y, p.z, 1.0);  
  
  // Varyings   
  vFresnel = fresnel ;
  vElevation = modelPositionAngle.y;
}`;

export default vertex;

// rotate vertext shader modelMatrix by y axis
