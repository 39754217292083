export const fragment = `#define GLSLIFY 1

uniform float uTime;
uniform vec3 uColor;
varying float vFresnel; 
varying float vElevation; 

#define uFresnelStrength 0.2
#define uHalosTimeFrequency 0.0005
#define uHalosFrequency 3.0
#define uHalosStrength 0.6

void main(){
        // Fresnel 
        float fresnel = vFresnel * uFresnelStrength;
        
        // float randomOnOff = fract(sin(uTime)*1.0);

        // float alternateOnOff = (sin(uTime/5000.0+0.2) * randomOnOff) + 0.4;

        // Halos   
         float halos = mod(vElevation * uHalosFrequency - uTime * uHalosTimeFrequency, 1.0) ;
         halos = pow(halos, 14.0);    
         halos *= uHalosStrength;
         
         halos = (halos ) + halos + 0.2;
       
         float halos2 =  mod(vElevation * uHalosFrequency - uTime * uHalosTimeFrequency, 1.0);
         halos2 = pow(halos2, 4.0);
         halos2 *= uHalosStrength;   
      


        // Strength
        float strength = halos + fresnel + 0.1 + halos2;
           
        // Color
        vec3 color = uColor * max(strength, 1.0);
        gl_FragColor = vec4(color, strength);

        // Debug fragment
        // gl_FragColor = vec4(0.5, 0.5, 0.5, 1.0);
}`;

export default fragment;
